<template>
  <div class="home mb-0">
    <CCard>
      <CCardBody>
        <h5 style="font-weight: 700">{{ getUserName }}</h5>
        <CRow class="d-flex justify-content-center p-2 dark-block mt-2">
          <CCol sm="12" lg="6" md="12" style="text-align: center">
            <div class="avatar-upload" col="6">
              <img
                :src="profilePic"
                class="rounded-circle"
                style="width: 50%"
                @error="$event.target.src = '/img/user_default_pic.png'"
                alt
              />
              <div class="upload-avatar">
                <input
                  id="imageUpload"
                  type="file"
                  ref="profilefileref"
                  name="image"
                  :accept="fileType"
                  @change="selectedFile"
                />
                <label for="imageUpload" class="p-2 font-weight-bold small mr-2"
                  >Upload photo</label
                >
                <CButton
                  v-if="profilePic != '/img/user_default_pic.png'"
                  class="font-weight-bold rounded-0 small remove-btn"
                  @click="deleteUserPic()"
                  >Remove</CButton
                >
              </div>
            </div>
            <div v-if="isImageSelected">
              <UploadDocument
                class="mt-4"
                :fileData="fileData"
                @closeModal="closeModal"
                :isProfilePic="true"
                v-on="$listeners"
                @upload="photoUpload"
              />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CToaster v-if="errorToaster.isError" position="bottom-right">
      <CToast :show="true" class="bg-danger text-white">
        {{ errorToaster.message }}
      </CToast>
    </CToaster>
    <modal
      modalTitle="NOTIFICATION"
      modalColor="primary"
      modalContent="Are you sure to remove profile picture?"
      :isShowPopup="deleteModalPopup"
      :buttons="['Cancel', 'Confirm']"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import Modal from "../../components/reusable/Modal.vue";
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import { mapActions, mapGetters } from "vuex";
import { Role } from "@/helpers/helper";
import { BASE_URL } from "@/service_urls";
export default {
  name: "UploadUserPic",
  components: {
    UploadDocument,
    Modal,
  },
  data() {
    return {
      isImageSelected: false,
      fileType: "image/png, image/jpeg, image/jpg, image/gif",
      fileData: {},
      Role,
      errorToaster: {
        isError: false,
        message: null,
      },
      deleteModalPopup: false,
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "uploaded",
      "getUserUploadedDocuments",
      "getRole",
      "getRoleScopes",
    ]),
    currentUserRole() {
      return this.getRoleScopes.length ? this.getRoleScopes[0] : "";
    },
    isCustomer() {
      return (
        [
          this.Role.customerAdmin,
          this.Role.customerRecruiter,
          this.Role.customerUser,
        ].includes(this.currentUserRole) || false
      );
    },
    profilePic() {
      let profileData = this.getUserUploadedDocuments
        .filter((document) => document.document_type_id === 2)
        .reduce((acc, cur) => {
          return cur;
        }, {});
      if (
        Object.keys(profileData).length &&
        this.getUser?.id == profileData.user_id
      ) {
        let _baseURL = BASE_URL;
        var _base_domain = _baseURL.substring(0, _baseURL.indexOf("/api"));
        return `${_base_domain}/api/v1/user_documents/${profileData.user_document_id}/file?user_id=${profileData.user_id}`;
      }
      return "/img/user_default_pic.png";
    },
    getUserName() {
      let username = this.getUser.first_name;
      let systemAdmin = 1;
      if (this.getUserId === systemAdmin) {
        username = this.getUser.user_name;
      }
      if (username) {
        return username.replace(/\w\S*/g, function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
      return false;
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.$refs.profilefileref.value = "";
        this.isImageSelected = false;
        this.fileData = {};
      }
    },
  },
  methods: {
    ...mapActions(["uploadUserPhoto", "deleteUserPhoto"]),
    showToaster({ message }) {
      this.$refs.profilefileref.value = "";
      this.errorToaster.isError = true;
      this.errorToaster.message = message;
      setTimeout(() => {
        this.errorToaster.isError = false;
        this.errorToaster.message = null;
      }, 5000);
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    selectedFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        this.fileData = file;
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.isImageSelected = true;
          }
        } else {
          const message = "Only png/jpeg/jpg/gif allowed";
          this.showToaster({ message });
        }
      } else {
        this.$refs.profilefileref.value = "";
        const message = "File too Big, please select a file less than 2mb";
        this.showToaster({ message });
      }
    },
    closeModal() {
      this.$refs.profilefileref.value = "";
      this.fileData = {};
      this.isImageSelected = false;
    },
    photoUpload(payload) {
      this.$store.commit("SET_UPLOADED");
      const document_type = payload.document_type || 1;
      if (this.isCustomer && this.getUser) {
        this.uploadUserPhoto({
          customer_uid: this.getUser.customer_id,
          user_id: this.getUser.id,
          [payload.name]: payload.value,
          document_type,
          ["fileName"]: payload.document_name,
        });
      } else {
        this.uploadPhoto({
          customer_uid,
          candidate_uid,
          user_id,
          [payload.name]: payload.value,
          document_type,
          ["fileName"]: payload.document_name,
        });
      }
    },
    deleteUserPic() {
      this.deleteModalPopup = true;
    },
    deleteModalCallBack(action) {
      if (action == "Confirm") {
        let profilePic = this.getUserUploadedDocuments
          .filter((document) => document.document_type_id === 2)
          .reduce((acc, cur) => {
            return cur;
          }, {});
        this.deleteUserPhoto({
          user_id: this.getUser?.id,
          user_document_id: profilePic.user_document_id,
        }).then(() => (this.deleteModalPopup = false));
      } else {
        this.deleteModalPopup = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-avatar {
  z-index: 1;
  border: 6px transparent solid;
}
.upload-avatar input {
  display: none;
}
.upload-avatar input + label {
  display: inline-block;
  text-align: center;
  margin-bottom: 0;
  background: darkgray;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.remove-btn {
  background-color: red;
  color: white;
}
</style>
