<template>
  <div class="col-sm-9 container-md mt-4 pt-4 pb-4 mb-4">
    <h4 class="text-primary mt-2 mb-4">Update Picture/Logo</h4>
    <CTabs
      variant="tabs"
      :justify="true"
      :fill="true"
      :active-tab="activeTab"
      @update:activeTab="updateTab"
    >
      <CTab title="Profile" :disabled="isImgUpdated">
        <UploadUserPic v-if="activeTab == 0" />
      </CTab>
      <CTab title="Facility Logo" :disabled="isImgUpdated">
        <UploadOrgPictures
          v-if="activeTab == 1"
          :document_type_id="17"
          :isImgUpdated="isImgUpdated"
          @imgUpdated="updateValueChange"
        />
      </CTab>
      <CTab title="Job Banner" :disabled="isImgUpdated">
        <UploadOrgPictures
          v-if="activeTab == 2"
          :document_type_id="19"
          :isImgUpdated="isImgUpdated"
          @imgUpdated="updateValueChange"
        />
      </CTab>
    </CTabs>
    <div class="text-center mx-0 mt-2 pb-3 mb-3 action-area">
      <CButton
        type="button"
        name="registration-home"
        class="btn rounded-0 px-5 btn-lg m-1 mt-3 float-left btn-outline-primary btn-lg mr-2"
        @click="navToCandidateList"
        >Home</CButton
      >
      <CButton
        class="px-5 m-1 btn-outline-primary rounded-0 mt-3 float-right primary btn-lg"
        @click="goingBack()"
        >Back</CButton
      >
    </div>
  </div>
</template>
<script>
import UploadUserPic from "@/containers/UploadOrgLogos/UploadUserPic";
import UploadOrgPictures from "@/containers/UploadOrgLogos/UploadOrgPictures";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "UploadOrganisationPictures",
  components: {
    UploadUserPic,
    UploadOrgPictures,
  },
  data() {
    return {
      activeTab: 0,
      isImgUpdated: false,
    };
  },
  computed: {
    ...mapGetters(["getOrgIDFromAccessToken"]),
  },
  methods: {
    ...mapActions(["fetchDocumentByOrgId"]),
    updateTab(value) {
      this.activeTab = value;
    },
    updateValueChange(value) {
      this.isImgUpdated = value;
    },
    navToCandidateList() {
      this.$router.push({ path: "/candidate-list" });
    },
    goingBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.fetchDocumentByOrgId({ organisation_id: this.getOrgIDFromAccessToken });
  },
};
</script>
