<template>
  <div>
    <CCard>
      <CCardBody>
        <h5 style="font-weight: 700">{{ getOrgName }}</h5>
        <CRow class="row mb-3 mt-3">
          <CRow
            class="justify-content-center m-0 mb-3"
            style="width: 100%"
            v-if="fetchImageUrl"
          >
            <div
              v-if="document_type_id == 17"
              style="width: 210px; height: 200px; border: 1px dashed gray"
              class="text-center parent_div"
            >
              <img
                id="preview_img"
                :src="getImageUrl"
                @error="$event.target.src = '/img/hospital.png'"
                class="rounded-circle ratio-1 child_div"
                style="max-height: 200px; width: 100%"
                alt="logo"
              />
            </div>
            <div
              v-if="document_type_id == 19"
              style="border: 1px dashed gray; width: 75%"
              class="text-center"
            >
              <img
                id="preview_img"
                :src="getImageUrl"
                @error="$event.target.src = '/img/job-image.jpg'"
                style="width: 99%; max-height: 250px"
                alt="logo"
              />
            </div>
          </CRow>
          <label class="col-lg-12 col-md-12">
            Upload Image
            <small v-if="document_type_id == 19">
              <CIcon
                v-c-tooltip="'Best Resolution - 72DPI (850x250 pixels)'"
                style="width: 2rem !important; height: 1.5rem !important"
                class="text-primary cursor-pointer"
                name="cil-info"
            /></small>
            <small class="text-primary"
              >(Tip:
              {{
                document_type_id == 19
                  ? "Upload the image to be shown in the Job Board"
                  : "Upload the logo for the facility"
              }})</small
            >
          </label>

          <CRow class="justify-content-between ml-3 mr-3" style="width: 100%">
            <div class="upload_file mb-3">
              <input
                id="upload-image"
                name="upload-image"
                type="file"
                ref="fileref"
                :accept="fileType"
                @change="handleFile"
              />
              <label
                for="upload-image"
                class="btn text-white font-weight-bolder rounded-1"
                >Upload File</label
              >
              <small
                class="ml-2"
                style="position: relative; top: 3px; font-size: 13px"
              >
                <input
                  type="checkbox"
                  :value="useCrop"
                  :checked="useCrop"
                  @input="useCrop = !useCrop"
                  class="mr-1"
                  id="crop_check"
                />
                <label for="crop_check" style="position: relative; top: -2px"
                  >Use Crop</label
                >
              </small>
            </div>
            <div v-if="isImgUpdated">
              <CButton class="btn-secondary mr-3" @click="resetForm()"
                >Cancel</CButton
              >
              <CButton class="btn-primary" @click="submit()">Update</CButton>
            </div>
          </CRow>
          <div v-if="cropperModal.isShowPopup">
            <CropperModal
              :isShowPopup="cropperModal.isShowPopup"
              :fileData="cropperModal.fileData"
              modalColor="primary"
              :buttonLabel="cropperModal.buttonLabel"
              @modalCallBack="cropperModalCallBack"
              :aspectRatio="cropperModal.aspectRatio"
            />
          </div>
        </CRow>
        <div class="documentScrolling">
          <table class="table">
            <thead>
              <tr>
                <th>File Name</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getDocuments" :key="index">
                <td>{{ item.document_name }}</td>
                <td>
                  <CButton variant="ghost" @click="deleteDocument(item)">
                    <i class="fas fa-trash"></i>
                  </CButton>
                </td>
              </tr>
              <tr v-if="!getDocuments.length">
                <td colspan="2">
                  <p class="text-center text-muted h6 mt-3">No Records Found</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="previewModalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <CToaster v-if="errorToaster.isError" position="bottom-right">
      <CToast :show="true" class="bg-danger text-white">
        {{ errorToaster.message }}
      </CToast>
    </CToaster>
    <modal
      modalTitle="NOTIFICATION"
      modalColor="primary"
      modalContent="Are you sure to delete this picture?"
      :isShowPopup="deleteModal.popup"
      :buttons="['Cancel', 'Confirm']"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import Modal from "../../components/reusable/Modal.vue";
import CropperModal from "@/components/reusable/CropperModal";
import PreviewModal from "@/components/reusable/PreviewModal";
import { mapActions, mapGetters } from "vuex";
import { appendAccessToken } from "@/helpers/helper";
import { BASE_URL } from "../../service_urls";

export default {
  name: "UploadOrgPictures",
  props: {
    document_type_id: {
      type: Number,
      default: 0,
    },
    isImgUpdated: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CropperModal,
    PreviewModal,
    Modal,
  },
  data() {
    return {
      payload: {},
      fileType: "image/png, image/jpeg, image/jpg, image/gif",
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 1,
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "",
        isShowPopup: false,
        buttons: ["Close"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: true,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      errorToaster: {
        isError: false,
        message: null,
      },
      deleteModal: {
        popup: false,
        item: {},
      },
      useCrop: true,
      imgUrl: "",
    };
  },
  computed: {
    ...mapGetters([
      "getOrgID",
      "getOrgIDFromAccessToken",
      "getDocumentByOrganisationId",
      "getOrgName",
    ]),
    getDocuments() {
      return (
        this.getDocumentByOrganisationId.filter(
          (val) => val.document_type_id == this.document_type_id
        ) || []
      );
    },
    fetchImageUrl() {
      if (this.payload?.file) {
        this.imgUrl = URL.createObjectURL(this.payload.file);
      } else {
        let _base_domain = `${window.location.origin}/api/v1`;
        this.imgUrl = appendAccessToken(
          `${_base_domain}/organisation/${this.getOrgIDFromAccessToken}/file/${this.getDocuments[0]?.organisation_document_uuid}`
        );
      }
      return true;
    },
    getImageUrl: {
      get() {
        return this.imgUrl;
      },
      set(value) {
        this.imgUrl = value;
      },
    },
  },
  methods: {
    ...mapActions([
      "createDocumentForOrganisation",
      "deleteDocumentForOrganisation",
      "showToast",
    ]),
    showToaster({ message }) {
      this.$refs.fileref.value = "";
      this.errorToaster.isError = true;
      this.errorToaster.message = message;
      setTimeout(() => {
        this.errorToaster.isError = false;
        this.errorToaster.message = null;
      }, 5000);
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    handleFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            if (this.useCrop) {
              this.cropperModal.fileData = file;
              this.cropperModal.isShowPopup = true;
              if (this.document_type_id == 17) {
                this.cropperModal.aspectRatio = 1;
              }
              if (this.document_type_id == 19) {
                this.cropperModal.aspectRatio = 2;
              }
            } else {
              this.payload = {
                ...this.payload,
                file,
                fileName: file.name ? file.name : "untitled.png",
              };
              this.$emit("imgUpdated", true);
            }
          }
        } else {
          const message = "Only png/jpeg/jpg/gif allowed";
          this.showToaster({ message });
        }
      } else {
        const message = "File too Big, please select a file less than 2mb";
        this.showToaster({ message });
      }
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        this.$refs.fileref.value = "";
      } else {
        let { fileBlob, fileName } = data;
        this.payload = { ...this.payload, fileName };
        this.payload.file = fileBlob;
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        this.$emit("imgUpdated", true);
      }
    },
    submit() {
      if (!this.payload.file) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please Choose Image File!",
        });
        return;
      }
      let finalPayload = {
        ...this.payload,
        organisation_id: this.getOrgIDFromAccessToken,
        document_type_id: this.document_type_id,
      };
      this.createDocumentForOrganisation(finalPayload).then(() => {
        this.resetForm();
      });
    },
    preview(data) {
      this.previewModal.modalTitle = "Image Preview";
      this.iframe.loaded = false;
      this.iframe.ispdf = false;
      this.iframe.isImg = true;
      this.iframe.pdf.ispdf = false;
      this.iframe.src = "https://" + window.location.host + data.link_to_doc;
      this.previewModal.isPreview = true;
      this.previewModal.isShowPopup = true;
    },
    previewModalCallBack() {
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    deleteDocument(item) {
      this.deleteModal.popup = true;
      this.deleteModal.item = item;
    },
    deleteModalCallBack(action) {
      if (action == "Confirm") {
        this.deleteDocumentForOrganisation({
          organisation_id: this.deleteModal?.item.organisation_id,
          organisation_document_uuid:
            this.deleteModal?.item.organisation_document_uuid,
        }).then(() => (this.deleteModal.popup = false));
      } else {
        this.deleteModal.popup = false;
      }
    },
    resetForm() {
      this.$emit("imgUpdated", false);
      this.$refs.fileref.value = "";
      this.payload = {};
      this.getImageUrl = `${BASE_URL}/organisation/${this.getOrgIDFromAccessToken}/file/${this.getDocuments[0]?.organisation_document_uuid}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload_file #upload-image {
  display: none;
}
.upload_file #upload-image + label {
  display: inline-block;
  text-align: center;
  margin-bottom: 0;
  background: #085d66e6;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  width: 110px;
}
.upload_file #upload-image + label:hover {
  background: #30757ce6;
}
.upload_file #upload-image + label:after {
  color: #30757ce6;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.parent_div {
  position: relative;
}
.child_div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
